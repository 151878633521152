import React, { Component } from "react";
// import { Button } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import axios from "axios";
import { teacherApiURL } from "../utils";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles} from "@material-ui/core/styles";


const useStyles = theme => ({
    button: {
      textTransform:'capitalize',
      border:'1px solid #f26522',
      borderRadius:'0px',
      padding:'2px 5px',
    },
  });

//   const styles = theme => ({
//     root: {
//         flexGrow: 1,
//       },
//       paper: {
//         padding: theme.spacing(2, 0, 2, 0),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.1)',
//       },
// });
     

class Delete extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      editorVlaue: "",
    };
  }

  deleteData = (e) => {
    e.preventDefault();
    const headers = {
      "Content-type": "application/json",
      Accept: "application/json",
    };    

    const body = {
      id: this.props.deleteID,
      table: this.props.table,
    };
    axios
      .post(teacherApiURL("delete"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          //alert("deleted")
          this.props.onSuccessDelete(true, this.props.deleteID);
        } else {
          this.props.onSuccessDelete(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      editorVlaue: content,
    });
  };

  render() {
    const { classes } = this.props; 
    
    return (
      <React.Fragment>
        {/*<Button variant="outlined" color="primary" onClick={(e) => this.deleteData(e,)}>Delete</Button>*/}
        {/* <Button variant="outlined" color="primary" onClick={(e) => {if(window.confirm('Are you sure? You want to delete this item')){this.deleteData(e)};}}>Delete</Button> */}
        <Button
          variant="outlined"
          color="default"
          className={classes.button}
          startIcon={<DeleteIcon style={{color:'#f26522'}} />}
          onClick={(e) => {
            if (window.confirm("Are you sure? You want to delete this item")) {
              this.deleteData(e);
            }
          }}
        >
          Delete
        </Button>
      </React.Fragment>
    );
  }
}

// export default TinyMceEditor;

export default  withStyles(useStyles)(Delete);


  