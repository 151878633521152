import React, { Component } from 'react';
import { apiURL, change_date_format, get_changed_time, sanitizeHtml } from '../utils';  
import Footer from '../include/Footer';
import Header from '../include/Header';
import { makeStyles, withStyles } from '@material-ui/core/styles'; 
import { CssBaseline, Grid, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Switch, FormControl, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';  
import DateFnsUtils from '@date-io/date-fns';  
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";  
import * as yup from 'yup'; 
import SearchIcon from '@material-ui/icons/Search'; 
import Helmet from 'react-helmet';
import Delete from "../delete/Delete";
import ClearIcon from '@material-ui/icons/Clear';
import Meta from "../meta/Meta";

import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';   
import Box from '@material-ui/core/Box';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import "../style/Style.css";  
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import PushNotification from "../push-notification/PushNotification";
import XLSX from 'xlsx';
import ExportExcelButton from '../utils/ExportExcelButton';
import { Link } from 'react-router-dom';
import FeedbackIcon from '@material-ui/icons/Feedback';



const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({

// }))

const useStyles = theme => ({
    buttonEdit: {
      textTransform:'capitalize',
      border:'1px solid #035eab',
      borderRadius:'0px',
      marginRight:'10px',
      padding:'2px 5px',
    },
    buttonAdd:{
      textTransform:'capitalize',
    },
    table:{
        minWidth: 650,
    },
   
   
  });

const blogFormSchema = yup.object().shape({
    name: yup.string().required("This field is required."),
    contact: yup 
      .number()
      .required("This field is required."),
    email: yup
      .string()
      .email()
      .required("This field is required."),
  }); 


 class Blog extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
           // drawerOpen: false,
            drawerOpen: true,
            showDialog: false,
            qualification: '',
            file: null,
            imagePreview: null,
            blogData: [],
            page: 1,
            per:20,
            total_pages: null,
            loading: false,
            imageEdit: false,
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            mode: '',  
            editorVlaue: '',
            selecteDate:  new Date(),
            changeDate: '',
            snackBarTimer: '',
            noData: '', 
        }
        this.handleDrawerOpen       = this.handleDrawerOpen.bind(this);
        this.blogAddDialog          = this.blogAddDialog.bind(this);
        this.blogDialogClose        = this.blogDialogClose.bind(this);
        this.changeHandler          = this.changeHandler.bind(this);
        this.onFormSubmit           = this.onFormSubmit.bind(this);
        this.onEditFormSubmit       = this.onEditFormSubmit.bind(this);
        this.editDialogOpen         = this.editDialogOpen.bind(this);
        this.handleEditorChange     = this.handleEditorChange.bind(this);
        this.handleDateChange       = this.handleDateChange.bind(this);
        this.changeStatus           = this.changeStatus.bind(this)
    }

    handleDrawerOpen(drawerState){
        this.setState({
          drawerOpen: drawerState,
        })
    }


    changeHandler = (e, type) => { 
        this.setState({ 
            [e.target.name]: e.target.value,
        }); 
    }
    handleImageChange = (e) => {
        this.setState({  
            imagePreview: URL.createObjectURL(e.target.files[0]),
            file:e.target.files[0],
            imageEdit: true
         })
    }
      
    removeImage = (e) => {
        this.setState({  
            imagePreview: "",
            file:"",
            imageEdit: false,
            image: ""
         })
    }

    componentDidMount() { 
        this._isMounted = true; 
        this.blogListData(); 
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
        
        
    }

    componentWillUnmount() {
        this._isMounted = false; 
    }
  
    blogListData = () => {
        this.setState({ loading: true });
        const { per, page, blogData } = this.state; 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "search": document.getElementById('search').value
        }
        axios.post(apiURL('blog?per_page='+per+'&page='+page), body, {headers: headers})
        .then(response => {
            
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({  
                    blogData: (resultData.total < 20)?resultData.result:[...this.state.blogData, ...resultData.result],
                    scrolling: false,
                    total_pages: resultData.total_pages,
                });
                this.setState({ loading: false, scrolling:true });
            }
            else{
                this.setState({ loading: false, scrolling: false, noData: "No Blog Found" }); 
            }
        }
        })
        .catch(error => {
            console.log(error)
        })
    }

    loadMore = () => { 
        if(this._isMounted){
            this.setState(
            prevState => ({
                page: prevState.page + 1,
                scrolling: true
            }),
            this.blogListData
            ); 
        }
      };

      handleScroll = () => {   
        if(this._isMounted){ 
            var lastLi = document.querySelector("Table"); 
            if(lastLi !== null){
                var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                var pageOffset = window.pageYOffset + window.innerHeight;
                if (pageOffset > lastLiOffset) {
                    if(this.state.scrolling){
                        this.loadMore();
                    }
                } 
            }
        }
      };

    onFormSubmit = (e) => { 
        e.preventDefault();
        //e.stopPropagation();  
        const formData  = new FormData();
        formData.append('file', this.state.file);
        formData.append('title', this.state.title);
        formData.append('select_date', this.state.selecteDate);  
        formData.append('change_date', change_date_format(this.state.selecteDate)+' '+get_changed_time(this.state.selecteDate, false)); 
        formData.append('description', this.state.editorVlaue);
        const headers = {
            'content-type': 'application/json',
            'Accept': 'application/json'
        }
        axios.post(apiURL('blog/add'),formData, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            {
                this.blogDialogClose();
                this.componentDidMount();
                this.setState({ imageEdit: false, imagePreview: null, file: null  }); 
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
            }
            else{
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: 'error',
                    snackBarTimer: 2000
                });
            }
           
        })
        .catch(error => {
            console.log(error)
        }) 
    }

    onEditFormSubmit = (e) => {
         //alert(this.state.name);
         e.preventDefault(); 
         const editFormData  = new FormData();
         editFormData.append('id', this.state.id);
         editFormData.append('title', this.state.title);
         editFormData.append('select_date', this.state.selecteDate);  
         editFormData.append('change_date', change_date_format(this.state.selecteDate)+' '+get_changed_time(this.state.selecteDate, false)); 
         editFormData.append('description', this.state.editorVlaue);
         if(this.state.file !== null){
             editFormData.append('file', this.state.file);
         }
         editFormData.append('exist_file', this.state.image);
         editFormData.append('image_change', this.state.imageEdit);
         const headers = {
             'content-type': 'application/json',
             'Accept': 'application/json'
         }
         axios.post(apiURL('blog/edit'),editFormData, {headers: headers})
         .then(response => {
             const resultData = response.data; 
             if(resultData.status === true || resultData.status === 1)
             {
                 this.setState({ imageEdit: false, imagePreview: null, file: null });
                 this.blogDialogClose();
                 this.componentDidMount();  
                 this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message, 
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
            else{
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: 'error',
                    snackBarTimer: 2000
                });
            }
         })
         .catch(error => {
             console.log(error)
         })
    }

    changeStatus = (id, status) => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.get(apiURL('blog/change_status/'+id+'/'+status), {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                const updatedBlog = this.state.blogData.map(blog=>{
                    if(blog.fldi_id === id){
                        const _blog = {...blog};
                        _blog.flg_status = status==="0"?"1":"0";
                        return _blog;
                    }
                    return  blog;
                });
                this.setState({ blogData: updatedBlog });
            }
        })
        .catch(error => {
            console.log(error)
        })
      }

    blogAddDialog = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'add',
            file: '',
            title: '',
            selecteDate: new Date(),
            editorVlaue: '', 
            image:''
        });
    }

    editDialogOpen = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'edit',
            id: e.fldi_id,
            image: e.fldv_image_url,
            title: e.fldv_title,
            selecteDate: e.fldv_selected_date,
            editorVlaue: e.fldt_description,
        });  
    }

    blogDialogClose = () => { 
        this.setState({ showDialog: false });
    }

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }

    handleEditorChange = (content, editor) => { 
        this.setState({
            editorVlaue: content
        });
      }

    handleDateChange = (date) => {  
        this.setState({
            selecteDate: new Date(date), 
            changeDate: change_date_format(date)
        });
    }
   
    searchHandler = (e) => {
        this.setState({page: 1, searchValue:e.target.value}, () => { 
            this.blogListData();  
          })
    }

    successDelete = (e, id, index) => {  
        if(e === true){
            const deleteData = this.state.blogData;
            deleteData.splice(index, 1);
            this.setState({
                blogData: deleteData, 
                showSnackbar: true, 
                snackBarMessage: "data deleted suceesfully", 
                severityColor: 'success',
                snackBarTimer: 3000
            }); 
        } 
    }

    exportFile() {
        let headers = [["Title", "Date", "Status", "Delete"]]
        this.state.blogData.forEach((value) => {
          let valueArray = [value.fldv_title, change_date_format(value.fldv_selected_date)+' '+get_changed_time(value.fldv_selected_date, true), (value.flg_status === '1')?"Active":"Deactive", (value.flg_is_deleted === '1')?"Not Delete":"Deleted"]
          headers.push(valueArray)
        })
    
        const wb = XLSX.utils.book_new()
        const wsAll = XLSX.utils.aoa_to_sheet(headers)
        
        XLSX.utils.book_append_sheet(wb, wsAll, "Blog")
            XLSX.writeFile(wb, "export-blog.xlsx")
      }


    render(){
        const { classes } = this.props; 
        const loadingCSS = {
            height: "30px",
            margin: "10px"
          };
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        return(
            <div>
                 <Helmet>
                    <title>Blog</title>
                </Helmet>
                <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)}  />
                <CssBaseline /> 
                <div style={{ paddingTop: '5rem', paddingLeft: (this.state.drawerOpen?drawerWidth+15:72+15), transition: 'padding 0.5s ease'   }}>
                    <main>    
                        <Container maxWidth="lg" > 
                        <Box  bgcolor="background.paper" mt={2} boxShadow={3}>
                        <Alert 
                            action={
                                <div>
                                    <TextField className="textField" type="text" placeholder="...Search"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                                        }} 
                                        size="small"
                                        id="search"
                                        variant="outlined"
                                        style={{fontColor:"#fff", marginRight:'1rem',}} 
                                        name="search"
                                        onChange={e => this.searchHandler(e)} 
                                    />
                                    {/*<Searchbar onChange={this.blogListData} />*/}
                                    <Button className={classes.buttonAdd} variant="contained" color="primary" startIcon={<AddCircleOutlineOutlinedIcon/>} onClick={this.blogAddDialog}> Add Blog </Button> 
                                    <ExportExcelButton clickProp={(e) => this.exportFile(e)}/> 
                                </div>
                            } 
                            variant="outlined" severity="default" boxShadow={3}><h2>Blog </h2>
                        </Alert>
                        </Box>
                            <Grid container spacing={3}  align="center" > 
                                <Grid item xs={12}>  
                                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                                <TableContainer  mt={1} component={Paper} className="customTable">
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead> 
                                            <TableCell>Id</TableCell> 
                                            <TableCell> Title</TableCell>
                                            <TableCell>Selected Date</TableCell>
                                            {/*<TableCell>Description</TableCell>
                                            <TableCell>Image</TableCell>*/}
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableHead>
                                        <TableBody> 
                                            {this.state.blogData.map((arrList, index) => {    
                                             return <TableRow key={arrList.fldi_id}>  
                                                    <TableCell>{index+1}</TableCell> 
                                                    <TableCell>{arrList.fldv_title}</TableCell>
                                                    <TableCell>{arrList.fldv_changed_date}</TableCell>
                                                    {/*<TableCell>{sanitizeHtml(arrList.fldt_description)}</TableCell>
                                                    <TableCell><img style={{width:"40px"}} src={arrList.fldv_image_url} alt={arrList.fldv_title} /></TableCell>*/}
                                                    <TableCell> <Switch size="small" checked={arrList.flg_status==="1"} color="primary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status)}/></TableCell>
                                                    <TableCell>
                                                        <Button className={classes.buttonEdit} variant="outlined" color="default" startIcon={<EditIcon style={{color:'#035eab', fontSize:'1rem',}} />} onClick={() => this.editDialogOpen(arrList)}>Edit</Button>
                                                        <Delete deleteID={arrList.fldi_id} table={'blog'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>
                                                        <Meta primaryId={arrList.fldi_id} useFor={"inner_page"} table={'blog'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>
                                                        <PushNotification id={arrList.fldi_id} body={arrList.fldt_description} title={arrList.fldv_title} url={'blogs'}/>
                                                        <Link to={'blog-comments/'+arrList.fldi_id+'/'+arrList.fldv_title.replace(/ /g, '-').toLowerCase()}>
                                                            <Button style={{ marginLeft:'10PX', }} className={classes.buttonEdit} variant="outlined" color="default" startIcon={<FeedbackIcon style={{color:'#035eab', marginLeft:'5PX',fontSize:'0.875rem',}} />}>Comment</Button>
                                                       </Link>
                                                    </TableCell>
                                                </TableRow>
                                            })} 
                                        </TableBody>
                                    </Table> 
                                    </TableContainer>
                                    </Box>
                              
                                    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
                                        <span style={loadingTextCSS}>Loading...</span>
                                        <span>{(this.state.scrolling)?this.state.noData:""}</span>
                                    </div>
                                    
                                </Grid>
                           </Grid> 
                           <Footer />
                        </Container>
                    </main>
                </div> 

                <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={'md'} disableEnforceFocus >
                    <form  id="courses"  onSubmit={this.state.mode === 'edit'?(e) => this.onEditFormSubmit(e):(e) => this.onFormSubmit(e) }  >
                        <DialogTitle id="form-dialog-title"><h3 style={{textAlign:'center'}}>{(this.state.mode === 'add')?'Add':'Edit'} Blog </h3></DialogTitle>
                        <DialogContent style={{overflowY: 'hidden'}}>  
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>   
                                    <TextField fullWidth type="text" label="Title" variant="outlined" name="title"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.title} />
                                </Grid> 
                                <Grid item xs={12} sm={6}> 
                                    <FormControl size="small" style={{width:'100%'}} > 
                                    <MuiPickersUtilsProvider size="small" utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        inputVariant="outlined"
                                        label="Choose Date"
                                        value={this.state.selecteDate}
                                        onChange={(date) => this.handleDateChange(date)}
                                        name="date"
                                        format="dd/MM/yyyy"
                                        showTodayButton
                                        minDate={new Date(this.state.selecteDate)} 
                                        size="small"
                                    />
                                    </MuiPickersUtilsProvider> 
                                    </FormControl>
                                </Grid>   
                                
                                <Grid item xs={12} sm={12}>
                                <Editor
                                    initialValue={this.state.editorVlaue}
                                    init={{
                                    height: 188,
                                    selector: 'textarea',  // change this value according to your HTML
                                   convert_urls: false,
                                    menubar: true,
                                    plugins: [
                                        'advlist autolink tinydrive lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    api_key: 'v2xbwrmnf5ixj35bcuymns7odhg03cvh4xuyud4wc0zjc0lh',
                                    tinydrive_token_provider: 'https://navdishaadmin.navneet.com/jwt/text_editor_key/api/jwt.php',
                                    toolbar:
                                        'undo redo | formatselect | bold italic underline backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help'
                                    }}
                                    onEditorChange={(e) => this.handleEditorChange(e)}
                                />
                                </Grid> 
                                <Grid item xs={12} sm={12}>
                                    {(this.state.imagePreview || this.state.image)?<ClearIcon  onClick={(e) => this.removeImage(e)} />: ""}
                                    {this.state.imageEdit === true?
                                        <img style={{width:'40px', display:'flex', marginBottom:'1rem', marginLeft:'12%'}} src={this.state.imagePreview}/>:
                                        <img style={{width:'40px', display:'flex', marginBottom:'1rem', marginLeft:'12%'}} src={this.state.image}/>
                                    }
                                    <Button variant="contained" startIcon={<ImageSearchIcon/>} color="primary" component="label">Select Main Image
                                        <input type="file" style={{ display: "none" }} onChange={this.handleImageChange} />
                                    </Button>
                                    
                                   
                                </Grid>  
                             </Grid>
                        </DialogContent>
                        <DialogActions className="buttonCancel">
                            <Button variant="contained" onClick={() => this.blogDialogClose()} color="secondary"> Cancel </Button>
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </div>
        );
    }
 }
  
 export default (withStyles)(useStyles)(Blog);