import React, { Component, Fragment } from "react";
import { apiURL } from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";
import { withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Grid,
  Container,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Switch,
  InputAdornment,Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import Helmet from "react-helmet";
import Delete from "../delete/Delete";
import Box from '@material-ui/core/Box';
import { TableContainer } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

//import { CSVLink } from 'react-csv'; 
import XLSX from 'xlsx';
import ExportExcelButton from '../utils/ExportExcelButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClearIcon from '@material-ui/icons/Clear';
import Moment from 'moment'; 

const drawerWidth = 280;
const useStyles = (makeStyles) => ({
  buttonAdd:{
    textTransform:'capitalize',
  },
});

const sampleScoolCollegeList = [
      {
        fldv_name:"A.T.U.Chand Sultana Anglo Urdu H.S. (12.05.004)",
        fldv_area:"Ahmednagar",
        fldv_taluka:"Ahmednagar",
        fldv_district:"Ahmednagar",
        fldv_institute_name:"High School",
      },
      {
        fldv_name:"Bansilal Katariya Secondary School (07.05.039)",
        fldv_area:"Hinganghat",
        fldv_taluka:"Hinganghat",
        fldv_district:"Wardha",
        fldv_institute_name:"High School",
      },
      {
        fldv_name:"Bhausaheb Firodiya High School (12.05.001)",
        fldv_area:"Ahmednagar",
        fldv_taluka:"Ahmednagar",
        fldv_district:"Ahmednagar",
        fldv_institute_name:"High School",
      },
      {
        fldv_name:"C B KIDS ENGLISH SCHOOL (1130330)",
        fldv_area:"AMBAJOGAI",
        fldv_taluka:"AMBAJOGAI",
        fldv_district:"BEED",
        fldv_institute_name:"CBSE PATTERN",
      }
    ];

class SchoolCollege extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      // drawerOpen: false,
      drawerOpen: true,
      showDialog: false, 
      teacherData: [],
      page: 1,
      per: 20,
      total_pages: null,
      loading: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      searchValue: "",
      noData: "",
      scrolling: true,
      file: null,
      fileName: "",
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.teacherListData = this.teacherListData.bind(this);
    this.snackBarClose    = this.snackBarClose.bind(this);
    this.teacherListData = this.teacherListData.bind(this);
    this.teacherListData = this.teacherListData.bind(this);
    this.teacherListData = this.teacherListData.bind(this);
    this.teacherListData = this.teacherListData.bind(this);
  }

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  componentDidMount() {
    this._isMounted = true;
    this.teacherListData();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  teacherListData = () => { 
    const { per, page, teacherData } = this.state;
    const headers = {
      "Content-type": "application/json",
    };
    console.log(this.state.searchValue);
    const body = {
      //"search": document.getElementById('search'),
      "search": this.state.searchValue,
    };
    axios.post(apiURL("school_collage?per_page="+per+"&page="+page), body, {headers: headers})
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          console.log(resultData);
          
         // this.setState({teacherData: resultData.result});
          if (resultData.status === true || resultData.status === 1) {
            this.setState({ 
              teacherData: resultData.total < 20 ? resultData.result : [...this.state.teacherData, ...resultData.result],
              scrolling: false,
              total_pages: resultData.total_pages,
            });
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({
              loading: false,
              scrolling: false,
              noData: "No Teachers Found",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error); 
      });
  }; 

  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: true,
        }),
        this.teacherListData
      );
    }
  };

  handleScroll = () => {
    if (this._isMounted) {
      var lastLi = document.querySelector("Table");
      if (lastLi !== null) {
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
           if (this.state.scrolling) {
            this.loadMore();
          }
        }
      }
    }
  };

  changeStatus = (id, status) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      id: id,
      status: status.toString(),
    };
    axios
      .post(apiURL("teacher/change_status"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updateTeacherData = this.state.teacherData.map((teacher) => {
            if (teacher.fldi_id === id) {
              const _teacher = { ...teacher };
              _teacher.flg_status = status === "0" ? "1" : "0";
              return _teacher;
            }
            return teacher;
          });
          this.setState({ teacherData: updateTeacherData });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchHandler = (e) => {
    this.setState({
       page: 1, 
       searchValue: e.target.value 
      },  () => { 
      this.teacherListData();  
    }); 
  };
 

  successDelete = (e, id, index) => {
    if (e === true) {
      const deleteTeacher = this.state.teacherData;
      deleteTeacher.splice(index, 1);
      this.setState({
        teacherData: deleteTeacher,
        showSnackbar: true,
        snackBarMessage: "data deleted suceesfully",
        severityColor: "success",
        snackBarTimer: 3000,
      });
    }
  };

  exportFile() {
    let teachers = [["Name", "Area", "Taluka", "District", "Institute Name", "Delete"]]
    this.state.teacherData.forEach((teacher) => {
      let teacherArray = [teacher.fldv_name, teacher.fldv_area, teacher.fldv_taluka, teacher.fldv_district, teacher.fldv_institute_name, (teacher.flg_is_deleted === '1')?"Not Delete":"Deleted"]
      teachers.push(teacherArray)
    })

    const wb = XLSX.utils.book_new()
    const wsAll = XLSX.utils.aoa_to_sheet(teachers)
    
    XLSX.utils.book_append_sheet(wb, wsAll, "All School College List")
        XLSX.writeFile(wb, "export-school-college-list.xlsx")
  }

  
  downloadSample() {
    let sampleList = [["SCHOOL NAME WITH INDEX CODE", "Area", "Taluka", "District", "Institute Name"]]
    sampleScoolCollegeList.forEach((list) => {
      let sampleArray = [list.fldv_name, list.fldv_area, list.fldv_taluka, list.fldv_district, list.fldv_institute_name]
      sampleList.push(sampleArray)
    })

    const wb = XLSX.utils.book_new()
    const wsAll = XLSX.utils.aoa_to_sheet(sampleList)
    
    XLSX.utils.book_append_sheet(wb, wsAll, "All School College List")
        XLSX.writeFile(wb, "school-college-sample-file.csv")
  }

  addDialog = (e) => { 
    this.setState({ 
        showDialog: true, 
    });
}

dialogClose = () => { 
    this.setState({ showDialog: false });
}

handleImageChange = (e) => { 
    console.log(e.target.files)
    //alert(e.target.files[0].name)
    const extension = e.target.files[0].name.split('.').pop();
    if(extension !== 'csv')
    {
        this.setState({ 
            showSnackbar: true, 
            snackBarMessage:  'support only .csv file',
            severityColor: 'error',
            snackBarTimer: 3000
        });
    }
    else{ 
    this.setState({  
        imagePreview: URL.createObjectURL(e.target.files[0]),
        file:e.target.files[0],
        imageEdit: true,
        fileName: e.target.files[0].name,
     })
    }
}

importFile = (e) => {
    e.preventDefault(); 
    const headers = {
        'content-type': 'application/json',
        'Accept': 'application/json'
    }
   
    const formData  = new FormData();
    formData.append('file', this.state.file); 

    axios.post(apiURL('school_collage/import'),formData, {headers: headers})
    .then(response => {
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        {
            this.setState({ imageEdit: false, imagePreview: null, file: null });
            this.dialogClose();
            this.componentDidMount();  
            this.setState({ 
               showSnackbar: true, 
               snackBarMessage:  resultData.message,
               severityColor: resultData.status === true?'success':'error',
               snackBarTimer: 3000
           });
        }
        else{
            this.setState({ 
                showSnackbar: true, 
                snackBarMessage:  resultData.message,
                severityColor: 'error',
                snackBarTimer: 2000
            });
       }
    })
    .catch(error => {
        console.log(error)
    })
}

removeFile = (e) => {
    this.setState({  
        imagePreview: "",
        file:"",
        imageEdit: false,
        fileName: ""
     })
}

  render() {
    const { classes } = this.props;
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const search = {
      border: "1px solid",
      float: "left",
      marginRight: "67px",
      background: "rgba(255, 255, 255, 0.29)",
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <React.Fragment>
        <Helmet>
          <title>Registered Teacher</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
            <Button onClick={(e) => this.downloadSample(e)} style={{marginLeft:"810px"}} color="inherit" className={classes.buttonAdd} startIcon={<GetAppIcon/>} color="primary" variant="outlined">Download Sample</Button>
            <Box  bgcolor="background.paper" mt={2} boxShadow={3}>
              <Alert
                action={
                  <div>
                    <TextField className="textField" type="text" placeholder="...Search"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                        }} 
                        size="small"
                        id="search"
                        variant="outlined"
                        style={{fontColor:"#fff", marginRight:'1rem',}} 
                        name="search"
                        onChange={e => this.searchHandler(e)} 
                    /> 
                    {/*<Button style={{marginLeft:"5px"}} color="inherit" className={classes.buttonAdd} startIcon={<GetAppIcon/>} color="secondary" variant="contained" onClick={(e) => this.exportFile(e)}>Export to Excel</Button>*/}
                    <ExportExcelButton clickProp={(e) => this.exportFile(e)}/>

                    <Button onClick={(e) => this.addDialog(e)} style={{marginLeft:"5px"}} color="inherit" className={classes.buttonAdd} startIcon={<PublishIcon/>} color="primary" variant="contained">Import to Excel</Button>
                    {/*<Button color="inherit" size="small" onClick={this.speakerAddDialog}> Add Speaker </Button> */}
                  </div>
                }
                variant="outlined" severity="default" boxShadow={3} >
               <h2> School/College List </h2>
              </Alert>
              </Box>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                <TableContainer  mt={1} component={Paper} className="customTable"> 
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell> Name</TableCell>
                        <TableCell>District</TableCell>
                        <TableCell>Taluka</TableCell> 
                        {/*<TableCell>Status</TableCell>*/}
                        <TableCell>Action</TableCell>  
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.teacherData.map((arrList, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{arrList.fldv_name}</TableCell>
                            <TableCell>{arrList.fldv_district}</TableCell>
                            <TableCell>{arrList.fldv_taluka}</TableCell>
                            {/*<TableCell> 
                              <Switch size="small" checked={arrList.flg_status === "1"} color="primary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status) } />
                            </TableCell>*/}
                            <TableCell> 
                              <Delete deleteID={arrList.fldi_id} table={"school_collage"} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index) } /> 
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  </TableContainer>
                </Box>  
                  <div ref={(loadingRef) => (this.loadingRef = loadingRef)} style={loadingCSS} >
                    <span style={loadingTextCSS}>Loading...</span>
                    <span>{this.state.scrolling ? this.state.noData : ""}</span>
                  </div>
                </Grid>
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>
        
        
        <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={'xs'} disableEnforceFocus>
                    <form  id="courses" ref={c => { this.form = c }} onSubmit={(e) => this.importFile(e) }  >
                        <DialogTitle id="form-dialog-title"><h3 style={{textAlign:'center'}}>Import School College</h3></DialogTitle>
                        <DialogContent style={{overflowY: 'hidden'}}>  
                            <Grid container spacing={3}> 
                                <Grid item xs={12} sm={12}>    
                                    <div>
                                        {(this.state.fileName)?<ClearIcon  onClick={(e) => this.removeFile(e)} />: ""}
                                        <p>{this.state.fileName}</p>
                                    </div>
                                    <br/>
                                    <Button variant="contained" startIcon={<FileCopyIcon/>} color="primary" component="label">Choose File
                                        <input type="file" style={{ display: "none" }} onChange={this.handleImageChange} />
                                    </Button> 
                                   
                                </Grid>  
                             </Grid>
                        </DialogContent>
                        <DialogActions className="buttonCancel">
                            <Button variant="contained" onClick={() => this.dialogClose()} color="secondary"> Cancel </Button>
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </DialogActions>
                    </form>
                </Dialog>


        <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={(e) => this.snackBarClose(e)}
        >
          <Alert
            onClose={(e) => this.snackBarClose(e)}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(SchoolCollege);
