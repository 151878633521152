import React, { Component } from "react";
import { apiURL, teacherApiURL } from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";

import axios from "axios";
import Helmet from "react-helmet";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import {
  CssBaseline,
  Grid,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Switch,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
const COLORS = ["#0088FE", "#00C49F", "#ad180d", "#FFBB28"];

class UserCount extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
      totalUser: "",
      migratedUser: "",
      newUser: "",
      pendingUser: "",
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.getUserCount = this.getUserCount.bind(this);
  }

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUserCount();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  handleData = (type) => {
    this.props.history.push("/teacher", { type });
  };
  getUserCount = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(teacherApiURL("dashboard/count_teacher"), {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            totalUser: resultData.result.total_user,
            migratedUser: resultData.result.migrant_user,
            newUser: resultData.result.new_user,
            pendingUser: resultData.result.pending_user,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { totalUser, migratedUser, newUser, pendingUser } = this.state;

    const data = [
      {
        name: "Total Users",
        value: totalUser,
        percentage: (totalUser / totalUser) * 100,
      },
      {
        name: "New Users",
        value: newUser,
        percentage: (7200 / totalUser) * 100,
      },
      {
        name: "Pending Users",
        value: pendingUser,
        percentage: (pendingUser / totalUser) * 100,
      },

      {
        name: "Migrated Users",
        value: migratedUser,
        percentage: (3000 / totalUser) * 100,
      },
    ];

    return (
      <div>
        <Helmet>
          <title>User Distribution</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? "280px" : "72px",
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <h2>User Information</h2>
              </Box>
              <Grid
                container
                spacing={3}
                align="center"
                style={{ marginBottom: "5px" }}
              >
                <Grid item xs={12}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    p={2}
                    mt={1}
                    height="100%"
                  >
                    <TableContainer
                      mt={1}
                      component={Paper}
                      className="customTable"
                    >
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Description
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Count
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Total User</TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleData("total")}
                            >
                              {" "}
                              {this.state.totalUser}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Migrated User</TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleData("migrant")}
                            >
                              {this.state.migratedUser}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>New User</TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleData("new_user")}
                            >
                              {this.state.newUser}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Pending User for Migration</TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleData("pending")}
                            >
                              {this.state.pendingUser}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    p={2}
                    mt={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    flexDirection="row"
                  >
                    <PieChart
                      width={300}
                      height={170}
                      style={{ position: "relative", zIndex: 1 }}
                    >
                      <Pie
                        data={data}
                        cx={150}
                        cy={80}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        labelLine={false}
                      >
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                    <div style={{ textAlign: "left", marginTop: "10px" }}>
                      {data.map((entry, index) => (
                        <div
                          key={`label-${index}`}
                          style={{ marginBottom: "5px" }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              width: "10px",
                              height: "10px",
                              backgroundColor: COLORS[index % COLORS.length],
                              borderRadius: "50%",
                              marginRight: "5px",
                              verticalAlign: "middle",
                            }}
                          ></span>
                          <span
                            style={{
                              fontSize: "12px",
                              verticalAlign: "middle",
                            }}
                          >
                            {entry.name}: {entry.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  </Box>
                </Grid> */}
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>
      </div>
    );
  }
}

export default UserCount;
