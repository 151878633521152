import { Card, Container, Grid } from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import upcomingIcon from "../images/upcomingicon.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import academic from "../images/academic.png";
import success from "../images/success.png";
import interview from "../images/interview.png";
import book from "../images/book.png";
import self from "../images/self.png";
import blog from "../images/blogicon.png";
import news from "../images/newsicon.png";
import axios from "axios";
import { apiURL, teacherApiURL } from "../utils";

const LiveArticles = () => {
  const [articleCounts, setArticleCounts] = useState({
    academic: 0,
    success: 0,
    interviews: 0,
    book: 0,
    self: 0,
    blogs: 0,
    news: 0,
  });

  useEffect(() => {
    fetchCount();
  }, []);

  const fetchCount = () => {
    const headers = {
      "Content-type": "application/json",
    };

    axios
      .get(teacherApiURL("article/fetch_all_category_counts"), { headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setArticleCounts({
            academic: Number(resultData.category_counts.academic_essays ? resultData.category_counts.academic_essays : 0 ),
            success: Number(resultData.category_counts.success_stories ? resultData.category_counts.success_stories : 0),
            interviews: Number(resultData.category_counts.interviews ? resultData.category_counts.interviews : 0),
            book: Number(resultData.category_counts.book_recommendations ? resultData.category_counts.book_recommendations : 0),
            self: Number(resultData.category_counts.self_development ? resultData.category_counts.self_development : 0),
            blogs: Number(resultData.category_counts.blogs ? resultData.category_counts.blogs : 0),
            news: Number(resultData.category_counts.news ? resultData.category_counts.news : 0),
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculatePercentage = (count, total) => {
    return total = total > 0 ? (count / total) * 100 : 0; 
  };

  const totalArticleCount = Object.values(articleCounts).reduce((a, b) => a + b,0);


  return (
    <Fragment>
      <Container maxWidth="lg" className="trainGrContain">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <h2>Live Articles!!!</h2>

            <Card className="card">
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <p>Total Articles</p>
                  <div className="details">
                    <img alt="img" src={academic} />
                    <div className="count">
                      <div className="eventName">
                        <span className="">Academic Essays</span>
                      </div>
                      <div className="progress">
                        <LinearProgress
                          variant="determinate"
                          className="linearProgress"
                          style={{
                            borderRadius: "5px",
                            height: "8px",
                            backgroundColor: "#ebcab8",
                          }}
                          classes={{
                            bar: "progressArticleBar",
                          }}
                          value={calculatePercentage(
                            articleCounts.academic,
                            totalArticleCount
                          )}
                        />
                      </div>
                    </div>
                    <div className="numberCount">
                      <span className="progressCount">
                        {articleCounts.academic}
                      </span>
                    </div>
                  </div>

                  <div className="details">
                    <img alt="img" src={success} />
                    <div className="count">
                      <div className="eventName">
                        <span className="">Success Stories</span>
                      </div>
                      <div className="progress">
                        <LinearProgress
                          variant="determinate"
                          value={calculatePercentage(
                            articleCounts.success,
                            totalArticleCount
                          )}
                          style={{
                            borderRadius: "5px",
                            height: "10px",
                            backgroundColor: "#ebcab8",
                          }}
                          classes={{
                            bar: "progressArticleBar",
                          }}
                        />
                      </div>
                    </div>
                    <div className="numberCount">
                      <span className="progressCount">
                        {articleCounts.success}
                      </span>
                    </div>
                  </div>

                  <div className="details">
                    <img alt="img" src={interview} />
                    <div className="count">
                      <div className="eventName">
                        <span className="">Interviews</span>
                      </div>
                      <div className="progress">
                        <LinearProgress
                          variant="determinate"
                          value={calculatePercentage(
                            articleCounts.interviews,
                            totalArticleCount
                          )}
                          style={{
                            borderRadius: "5px",
                            height: "10px",
                            backgroundColor: "#ebcab8",
                          }}
                          classes={{
                            bar: "progressArticleBar",
                          }}
                        />
                      </div>
                    </div>
                    <div className="numberCount">
                      <span className="progressCount">
                        {articleCounts.interviews}
                      </span>
                    </div>
                  </div>

                  <div className="details">
                    <img alt="img" src={book} />
                    <div className="count">
                      <div className="eventName">
                        <span className="">Book Recommendations</span>
                      </div>
                      <div className="progress">
                        <LinearProgress
                          variant="determinate"
                          value={calculatePercentage(
                            articleCounts.book,
                            totalArticleCount
                          )}
                          style={{
                            borderRadius: "5px",
                            height: "10px",
                            backgroundColor: "#ebcab8",
                          }}
                          classes={{
                            bar: "progressArticleBar",
                          }}
                        />
                      </div>
                    </div>
                    <div className="numberCount">
                      <span className="progressCount">
                        {articleCounts.book}
                      </span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <p>Total Articles</p>
                  <div className="details">
                    <img alt="img" src={self} />
                    <div className="count">
                      <div className="eventName">
                        <span className="">Self Development</span>
                      </div>
                      <div className="progress">
                        <LinearProgress
                          variant="determinate"
                          className="linearProgress"
                          style={{
                            borderRadius: "5px",
                            height: "10px",
                            backgroundColor: "#ebcab8",
                          }}
                          classes={{
                            bar: "progressArticleBar",
                          }}
                          value={calculatePercentage(
                            articleCounts.self,
                            totalArticleCount
                          )}
                        />
                      </div>
                    </div>
                    <div className="numberCount">
                      <span className="progressCount">
                        {articleCounts.self}
                      </span>
                    </div>
                  </div>

                  <div className="details">
                    <img alt="img" src={blog} />
                    <div className="count">
                      <div className="eventName">
                        <span className="">Blogs</span>
                      </div>
                      <div className="progress">
                        <LinearProgress
                          variant="determinate"
                          value={calculatePercentage(
                            articleCounts.blogs,
                            totalArticleCount
                          )}
                          style={{
                            borderRadius: "5px",
                            height: "10px",
                            backgroundColor: "#ebcab8",
                          }}
                          classes={{
                            bar: "progressArticleBar",
                          }}
                        />
                      </div>
                    </div>
                    <div className="numberCount">
                      <span className="progressCount">
                        {articleCounts.blogs}
                      </span>
                    </div>
                  </div>

                  <div className="details">
                    <img alt="img" src={news} />
                    <div className="count">
                      <div className="eventName">
                        <span className="">News</span>
                      </div>
                      <div className="progress">
                        <LinearProgress
                          variant="determinate"
                          value={calculatePercentage(
                            articleCounts.news,
                            totalArticleCount
                          )}
                          style={{
                            borderRadius: "5px",
                            height: "10px",
                            backgroundColor: "#ebcab8",
                          }}
                          classes={{
                            bar: "progressArticleBar",
                          }}
                        />
                      </div>
                    </div>
                    <div className="numberCount">
                      <span className="progressCount">
                        {articleCounts.news}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default LiveArticles;
