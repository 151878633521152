import React, { Component, Fragment } from "react";
import { apiURL, registerAPIURL } from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";
import { withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Grid,
  Container,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Switch,
  InputAdornment,
  Button,
  Popover,
  IconButton,
  MenuItem,
  Select,
  Typography,
  FormControl,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import Helmet from "react-helmet";
import Delete from "../delete/Delete";
import Box from "@material-ui/core/Box";
import { TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import XLSX from "xlsx";
import ExportExcelButton from "../utils/ExportExcelButton";
import Moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { CloseRounded } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
const drawerWidth = 280;
const useStyles = (makeStyles) => ({
  delButton: {
    textTransform: "capitalize",
    border: "1px solid #f26522",
    borderRadius: "0px",
    padding: "2px 5px",
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  closeButton: {
    padding: 2,
    fontSize: 24,
  },
  selectField: {
    padding: "0rem",
  },
});
const cities = [
  "Achalpur",
  "Ahmednagar",
  "Akola",
  "Amravati",
  "Ambarnath",
  "Aurangabad",
  "Barshi",
  "Beed",
  "Bhivandi-Nizampur",
  "Bhusawal",
  "Chandrapur",
  "Dhule",
  "Gondia",
  "Hinganghat",
  "Ichalkaranji",
  "Jalgaon",
  "Jalna",
  "Kalyan-Dombivli",
  "Kolhapur",
  "Latur",
  "Malegaon",
  "Mira-Bhayandar",
  "Mumbai City",
  "Mumbai Suburban",
  "Nagpur",
  "Nanded Waghala",
  "Nandurbar",
  "Nashik",
  "Navi Mumbai",
  "Osmanabad",
  "Palghar",
  "Panvel",
  "Parbhani",
  "Pimpri-Chinchwad",
  "PMC, Pune",
  "Ratnagiri",
  "Sangli-Miraj-Kupwad",
  "Satara",
  "Sindhudurg",
  "Solapur",
  "Thane",
  "Ulhasnagar",
  "Udgir",
  "Vasai-Virar City MC",
  "Wardha",
  "Yavatmal",
];

class RegisteredUsers extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
      teacherData: [],
      page: 1,
      per: 50,
      total_pages: null,
      loading: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      searchValue: "",
      noData: "",
      fromDate: null,
      toDate: null,
      anchorEl: null,
      openPopover: false,
      filteredTeacherData: [],
      currentPage: 1,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.teacherListData = this.teacherListData.bind(this);
    this.snackBarClose = this.snackBarClose.bind(this);
  }

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  componentWillMount() {
    this._isMounted = true;
    this.teacherListData();
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.teacherData !== this.state.teacherData) {
  //     this.extractUniqueCities();
  //   }
  // }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget, openPopover: true });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, openPopover: false });
  };
  handleFromDateChange = (date) => {
    this.setState({ fromDate: date }, () => this.filterTeacherData());
  };

  handleToDateChange = (date) => {
    this.setState({ toDate: date }, () => this.filterTeacherData());
  };
  handleCityFilterChange = (event) => {
    const selectedCity = event.target.value;
    this.setState({ searchValue: selectedCity }, () => {
      this.teacherListData();
    });
  };
  handleChangePerPage = (event) => {
    this.setState({ per: event.target.value, page: 1 }, () => {
      this.teacherListData();
    });
  };
  // extractUniqueCities = () => {
  //   const citites = [
  //     ...new Set(
  //       this.state.teacherData.map((event) =>
  //         event.city_name === null || event.city_name === ""
  //           ? event.fldi_city
  //           : event.city_name
  //       )
  //     ),
  //   ];

  //   this.setState({ uniqueCities: citites });
  // };
  clearFilters = () => {
    this.setState(
      {
        fromDate: null,
        toDate: null,
      },
      () => {
        this.filterTeacherData();
      }
    );
  };

  filterTeacherData = () => {
    const { fromDate, toDate, teacherData, searchValue } = this.state;
    let filteredData = [...teacherData];

    if (searchValue !== "") {
      filteredData = filteredData.filter((teacher) =>
        teacher.city_name === null || teacher.city_name === ""
          ? teacher.fldi_city.toLowerCase().includes(searchValue.toLowerCase())
          : teacher.city_name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (fromDate && toDate) {
      filteredData = filteredData.filter((teacher) => {
        const teacherDate = Moment(teacher.flddt_added_date);
        return teacherDate.isBetween(fromDate, toDate, null, "[]");
      });
    }

    this.setState({ filteredTeacherData: filteredData });
  };

  handlePageChange = (page) => {
    // Update current page state when a page is clicked
    this.setState({ page: page }, () => {
      this.teacherListData(); // Update data when page changes
    });
  };
  teacherListData = () => {
    const { per, page, searchValue } = this.state;
    const headers = {
      "Content-type": "application/json",
    };

    const webinar_id = this.props.location.state
      ? this.props.location.state.webinar_id
      : undefined;

    const body = {
      // search: this.state.searchValue,
      webinar_id: webinar_id || "",
    };
    axios
      .post(
        apiURL(`webinar/get_registered_user?per_page=${per}&page=${page}`),
        body,
        { headers: headers }
      )
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          console.log(resultData);
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              teacherData: resultData.result,

              scrolling: false,
              total_pages: resultData.total_pages,
            });
            this.filterTeacherData();
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({
              loading: false,
              scrolling: false,
              noData: "",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangePerPage = (event) => {
    this.setState({ per: event.target.value, page: 1 }, () => {
      this.teacherListData();
    });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page, page: page }, () => {
      this.teacherListData();
    });
  };
  changeStatus = (id, status) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      id: id,
      status: status.toString(),
    };
    axios
      .post(apiURL("teacher/change_status"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updateTeacherData = this.state.teacherData.map((teacher) => {
            if (teacher.fldi_id === id) {
              const _teacher = { ...teacher };
              _teacher.flg_status = status === "0" ? "1" : "0";
              return _teacher;
            }
            return teacher;
          });
          this.setState({ teacherData: updateTeacherData });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // searchHandler = (e) => {
  //   this.setState(
  //     {
  //       page: 1,
  //       searchValue: e.target.value,
  //     },
  //     () => {
  //       this.teacherListData();
  //     }
  //   );
  // };

  successDelete = (e, id, index) => {
    e.preventDefault();

    const headers = {
      "Content-type": "application/json",
    };

    const webinar_id = this.props.location.state
      ? this.props.location.state.webinar_id
      : undefined;

    const body = {
      // search: this.state.searchValue,
      webinar_id: webinar_id || "",
      user_id: id,
    };
    axios
      .post(registerAPIURL(`join_webinar_user/delete`), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;

          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              showSnackbar: true,
              snackBarMessage: "data deleted suceesfully",
              severityColor: "success",
              snackBarTimer: 3000,
            });
            this.componentWillMount();
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  exportFile() {
    let teachers = [
      [
        "Name",
        "Email",
        "Phone Number",
        "City",
        "Subject",
        "School / College",
        "Board & Medium",
        "Grades Taught/Managed",

        "Registered Date",
        "Source",
        "Status",
        "Delete",
      ],
    ];
    this.state.teacherData.forEach((teacher) => {
      let teacherArray = [
        teacher.fldv_first_name === ""
          ? teacher.fldv_name
          : teacher.fldv_first_name,
        teacher.fldv_email,
        teacher.fldv_contact,

        teacher.fldi_city,

        teacher.fldv_subjects_taught === ""
          ? teacher.fldv_subject
          : teacher.fldv_subjects_taught,
        teacher.fldv_school_college,
        teacher.fldv_board_medium,
        teacher.fldv_grades_taught,
        Moment(teacher.flddt_updated_date).format("D MMMM, YYYY"),
        teacher.fldv_source,
        teacher.flg_status === "1" ? "Active" : "Deactive",
        teacher.flg_is_deleted === "1" ? "Not Delete" : "Deleted",
      ];
      teachers.push(teacherArray);
    });

    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(teachers);

    XLSX.utils.book_append_sheet(wb, wsAll, "All Teachers");
    XLSX.writeFile(wb, "export-regsiterd-teachers.xlsx");
  }
  renderPaginationButtons = () => {
    const { total_pages, currentPage } = this.state;

    if (!total_pages || total_pages <= 1) return null;

    const buttons = [];
    const maxButtons = 3;
    let startPage = 1;
    let endPage = total_pages;

    if (total_pages > maxButtons) {
      if (currentPage <= 3) {
        endPage = maxButtons;
      } else if (currentPage >= total_pages - 2) {
        startPage = total_pages - maxButtons + 1;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    buttons.push(
      <Button
        key="prev"
        size="small"
        disabled={currentPage === 1}
        onClick={() => this.handlePageChange(currentPage - 1)}
      >
        Prev
      </Button>
    );

    if (startPage > 1) {
      buttons.push(
        <Button
          key={1}
          size="small"
          variant={currentPage === 1 ? "contained" : "outlined"}
          onClick={() => this.handlePageChange(1)}
        >
          1
        </Button>
      );
    }

    if (startPage > 1) {
      buttons.push(
        <span
          key="prevEllipsis"
          style={{
            width: "36px",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          ...
        </span>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          size="small"
          variant={currentPage === i ? "contained" : "outlined"}
          onClick={() => this.handlePageChange(i)}
        >
          {i}
        </Button>
      );
    }

    if (endPage < total_pages) {
      buttons.push(
        <span
          key="nextEllipsis"
          style={{
            width: "36px",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          ...
        </span>
      );
    }

    if (endPage < total_pages) {
      buttons.push(
        <Button
          key={total_pages}
          size="small"
          variant={currentPage === total_pages ? "contained" : "outlined"}
          onClick={() => this.handlePageChange(total_pages)}
        >
          {total_pages}
        </Button>
      );
    }

    buttons.push(
      <Button
        key="next"
        size="small"
        disabled={currentPage === total_pages}
        onClick={() => this.handlePageChange(currentPage + 1)}
      >
        Next
      </Button>
    );

    return buttons;
  };
  render() {
    const { classes } = this.props;
    const { anchorEl, openPopover, page, per } = this.state;
    const startIndex = (page - 1) * per + 1;
    const endIndex = Math.min(startIndex + per - 1, startIndex + per - 1);
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const search = {
      border: "1px solid",
      float: "left",
      marginRight: "67px",
      background: "rgba(255, 255, 255, 0.29)",
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <React.Fragment>
        <Helmet>
          <title>Registered Teacher</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <Alert
                  action={
                    <div>
                      {/* <Typography variant="body1">Items per page:</Typography> */}

                      {/* <Select
                        labelId="per-page-label"
                        id="per-page"
                        value={this.state.per}
                        onChange={this.handleChangePerPage}
                        style={{ marginRight: "1rem" }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          elevation: 0,
                        }}
                      >
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                      </Select>

                      <Select
                        labelId="city-filter-label"
                        id="city-filter"
                        value={this.state.searchValue}
                        onChange={this.handleCityFilterChange}
                        style={{ marginRight: "1rem" }}
                        displayEmpty
                        renderValue={(selected) =>
                          selected ? selected : "City"
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          elevation: 0,
                        }}
                      >
                        <MenuItem value="">All</MenuItem>

                        {cities.map((city, index) => (
                          <MenuItem key={index} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select> */}
                      {/* <Button
                        onClick={this.handlePopoverOpen}
                        color="inherit"
                        style={{ border: "1px solid black" }}
                      >
                        Date{" "}
                      </Button>
                      <Popover
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={this.handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            label="From Date"
                            format="MM/dd/yyyy"
                            value={this.state.fromDate}
                            onChange={this.handleFromDateChange}
                          />
                          <DatePicker
                            label="To Date"
                            format="MM/dd/yyyy"
                            value={this.state.toDate}
                            onChange={this.handleToDateChange}
                          />
                        </MuiPickersUtilsProvider>
                        {(this.state.fromDate !== null ||
                          this.state.toDate !== null) && (
                          <IconButton
                            className={classes.closeButton}
                            onClick={this.clearFilters}
                          >
                            <CloseRounded />{" "}
                          </IconButton>
                        )}
                      </Popover> */}

                      <ExportExcelButton
                        clickProp={(e) => this.exportFile(e)}
                      />
                    </div>
                  }
                  variant="outlined"
                  severity="default"
                  boxShadow={3}
                >
                  <h2> Registered User </h2>
                </Alert>
              </Box>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    px={2}
                    py={2}
                    mt={1}
                  >
                    <TableContainer
                      mt={1}
                      component={Paper}
                      className="customTable"
                    >
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell> Name</TableCell>
                            <TableCell>Email Address</TableCell>
                            <TableCell>Phone Number </TableCell>
                            <TableCell>City </TableCell>
                            <TableCell> Subject </TableCell>
                            <TableCell>School / College </TableCell>
                            <TableCell>Board & Medium</TableCell>
                            <TableCell>Grades Taught/Managed</TableCell>
                            <TableCell>Registered Date</TableCell>
                            <TableCell>Source </TableCell>
                            {/* <TableCell>Status</TableCell> */}
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.filteredTeacherData.map(
                            (arrList, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{startIndex + index}</TableCell>
                                  <TableCell>
                                    {arrList.fldv_first_name === ""
                                      ? arrList.fldv_name
                                      : arrList.fldv_first_name}
                                  </TableCell>
                                  <TableCell>{arrList.fldv_email}</TableCell>
                                  <TableCell>{arrList.fldv_contact}</TableCell>
                                  <TableCell>{arrList.fldi_city}</TableCell>
                                  <TableCell>
                                    {arrList.fldv_subjects_taught === ""
                                      ? arrList.fldv_subject
                                      : arrList.fldv_subjects_taught}
                                  </TableCell>
                                  <TableCell>
                                    {arrList.fldv_school_college}
                                  </TableCell>
                                  <TableCell>
                                    {arrList.fldv_board_medium}
                                  </TableCell>
                                  <TableCell>
                                    {arrList.fldv_grades_taught}
                                  </TableCell>
                                  <TableCell>
                                    {Moment(arrList.registered_date).format(
                                      "D MMMM, YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell>{arrList.fldv_source}</TableCell>
                                  {/* <TableCell>
                                    <Switch
                                      size="small"
                                      checked={arrList.flg_status === "1"}
                                      color="primary"
                                      onChange={() =>
                                        this.changeStatus(
                                          arrList.fldi_id,
                                          arrList.flg_status
                                        )
                                      }
                                    />
                                  </TableCell> */}
                                  <TableCell>
                                    <Button
                                      variant="outlined"
                                      color="default"
                                      className={classes.delButton}
                                      startIcon={
                                        <DeleteIcon
                                          style={{ color: "#f26522" }}
                                        />
                                      }
                                      onClick={(e) => {
                                        if (
                                          window.confirm(
                                            "Are you sure? You want to delete this item"
                                          )
                                        ) {
                                          this.successDelete(
                                            e,
                                            arrList.fldi_id
                                          );
                                        }
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <div style={loadingCSS}>
                    <span style={loadingTextCSS}>Loading...</span>
                    <span>{this.state.scrolling ? this.state.noData : ""}</span>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                justify="flex-end"
                style={{ marginTop: "0.8rem", marginBottom: "1.5rem" }}
              >
                <Grid item>{this.renderPaginationButtons()}</Grid>
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={(e) => this.snackBarClose(e)}
        >
          <Alert
            onClose={(e) => this.snackBarClose(e)}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(RegisteredUsers);
